const EventsList = () => import('./components/EventsList')
const EventsManage = () => import('./components/EventsManage')
const AgendasList = () => import('./components/AgendasList')
const AgendasManage = () => import('./components/AgendasManage')
const EventViewAgendas = () => import('./components/EventViewAgendas')

export default [
  {
    name: 'events.index',
    path: '/events/list',
    component: EventsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'event',
      permission: {
        context: 'events',
        access: 'write'
      }
    }
  },
  {
    name: 'events.view.agendas',
    path: '/events/view/agendas/:id',
    component: EventViewAgendas,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'events',
        access: 'read'
      }
    }
  },
  {
    name: 'events.agendas.index',
    path: '/agendas/list',
    component: AgendasList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'event',
      permission: {
        context: 'events',
        access: 'read'
      }
    }
  },
  {
    name: 'events.manage',
    path: '/events/manage/:id?',
    component: EventsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'event',
      permission: {
        context: 'events',
        access: 'write'
      }
    }
  },
  {
    name: 'events.agendas.manage',
    path: '/agendas/manage/:id?',
    component: AgendasManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'event',
      permission: {
        context: 'events',
        access: 'write'
      }
    }
  }
]
